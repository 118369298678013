/**
 * @file
 * Styles for tables
 */

@import "variables";

//
// Tables
//

// Must be defined outside a selector for scope reasons.
$table-size: $fs-n;

table {
  @include adjust-font-size-to($table-size);
  // Align every third line to every second baseline.
  @include adjust-leading-to(1.5, $table-size);
  @include rhythm(1, 0, 0, 1, $table-size);
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

tr.odd, tr.even {
  background: white;
  border-color: $grey-light;
}

tr.odd {
  background: #eee;
}

tr.even {
}

legend {
  font-family: $secondary-typeface;
  font-size: $fs-l;
}

fieldset {
  border-color: $grey-light;
  margin: 0 0 10px;
}

thead {
  th {
    vertical-align: bottom;
  }

  &:first-child tr {
    th,
    td {
      // Set to transparent to preserve vertical rhythm.
      border-color: transparent;
    }
  }
}

th,
td {
  border-color: $grey-light;
  border-bottom: 0 none;
  @include rhythm(0, .167, .167, 0, $table-size);
  @include leading-border(1px, .167, $table-size, solid);
  text-align: left;
  vertical-align: top;
  padding: 5px;
}

th {
  font-weight: bold;
}

caption {
  caption-side: bottom;
  @include leader(1/3);
  padding-left: .5em;
  text-align: left;
  font-style: italic;
  font-weight: normal;
  font-size: $fs-l;
  font-family: $secondary-typeface;
  //opacity: .999;
}